@font-face {
  font-family: 'icon';
  src:
    url('fonts/icon.ttf?c8w8j3') format('truetype'),
    url('fonts/icon.woff?c8w8j3') format('woff'),
    url('fonts/icon.svg?c8w8j3#icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-schedule:before {
  content: "\e8b5";
}
.icon-add:before {
  content: "\e145";
}
.icon-archive:before {
  content: "\e149";
}
.icon-back:before {
  content: "\e5c4";
}
.icon-money:before {
  content: "\e227";
}
.icon-comment:before {
  content: "\e0b7";
}
.icon-close:before {
  content: "\e5cd";
}
.icon-copy:before {
  content: "\e14d";
}
.icon-edit:before {
  content: "\e254";
}
.icon-delete:before {
  content: "\e872";
}
.icon-done:before {
  content: "\e876";
}
.icon-done_all:before {
  content: "\e877";
}
.icon-error:before {
  content: "\e000";
}
.icon-error_outline:before {
  content: "\e001";
}
.icon-face:before {
  content: "\e87c";
}
.icon-previous:before {
  content: "\e314";
}
.icon-next:before {
  content: "\e315";
}
.icon-money_off:before {
  content: "\e25c";
}
.icon-pdf:before {
  content: "\e415";
}
.icon-priority_high:before {
  content: "\e645";
}
.icon-redo:before {
  content: "\e15a";
}
.icon-save:before {
  content: "\e161";
}
.icon-security:before {
  content: "\e32a";
}
.icon-send:before {
  content: "\e163";
}
.icon-restore:before {
  content: "\e8ba";
}
.icon-watch_later:before {
  content: "\e924";
}
