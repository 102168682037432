@media print {
  body {
    background-color: white;
  }
}

* {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1em;
}

#root {
  display: flex;
  width: 100%;
  height: 100vh;
}
